import React, { useCallback, useState, forwardRef } from 'react';
import { SxStyleProp } from 'theme-ui';

import { ClassNameProps } from '@ComponentProps';
import { mergeRefs } from '@Core/mergeRefs';

export const ARROW_SIZE = 13;

export const tooltipStyles: SxStyleProp = {
  zIndex: 'tooltip',
  position: 'absolute',
  padding: 'xs',
  borderColor: 'tooltipBorder',
  borderStyle: 'solid',
  borderWidth: 'outlinedStrokeWeight',
  backgroundColor: 'white',
  boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 11px -1px rgba(0, 0, 0, 0.15)',
};

export const arrowStyles: SxStyleProp = {
  transform: 'translate(-50%, -50%) rotate(45deg)',
  width: ARROW_SIZE,
  height: ARROW_SIZE,
  borderColor: 'tooltipBorder',
  borderStyle: 'solid',
  borderTopWidth: 'outlinedStrokeWeight',
  borderLeftWidth: 'outlinedStrokeWeight',
  top: -1,
  backgroundColor: 'white',
};

export const Popover: React.FC<ClassNameProps> = forwardRef<HTMLDivElement, ClassNameProps>(
  ({ children }, ref) => {
    const [element, setElement] = useState<HTMLElement>();

    const callbackRef = useCallback((element) => {
      if (element) {
        setElement(element);
      }
    }, []);

    let leftAdjustmentPx = '0px';
    if (element) {
      const boundingRect = element.getBoundingClientRect();
      if (boundingRect.left < 0) {
        const styles = window.getComputedStyle(element);
        leftAdjustmentPx = `${boundingRect.left * -1 + parseInt(styles.paddingLeft, 10)}px`;
      } else if (boundingRect.right === window.innerWidth) {
        leftAdjustmentPx = `-${(boundingRect.right - boundingRect.width) / 2}px`;
      }
    }

    return (
      <div
        data-id="tooltip-content"
        ref={mergeRefs([callbackRef, ref])}
        sx={{
          transform: 'translateX(-50%)',
          left: `calc(50% + ${leftAdjustmentPx})`,
          marginTop: '3xs',
          ...tooltipStyles,
        }}
      >
        <div
          sx={{
            position: 'absolute',
            left: `calc(50% - ${leftAdjustmentPx})`,
            ...arrowStyles,
          }}
        />
        {children}
      </div>
    );
  },
);
